import React, { useEffect, useState } from "react"
import Section from "@talentinc/gatsby-theme-ecom/components/Section/Section"
import { Box, Grid, Stack, Typography, Pagination, InputBase, Button } from "@mui/material"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import { useBETelemetry } from "@talentinc/gatsby-theme-ecom/hooks/useTelemetry"
import { navigate } from "gatsby"
import { useStyles } from "./style"
import SearchIcon from "@mui/icons-material/Search"
import ClearIcon from "@mui/icons-material/Clear"
import { useTranslation } from "react-i18next"
import { ArticleByCategory } from "@talentinc/gatsby-theme-ecom/types/blog/category"
import { getLinkUrl } from "@talentinc/gatsby-theme-ecom/utils/blog/url"

export default function BodySection({
  noResultText,
  searchVal,
  articlesData,
  pagination,
  onSearchSubmit,
}: {
  noResultText: string
  searchVal?: string
  articlesData: ArticleByCategory
  pagination: {
    currentPage: number
    numPages: number
    onChange: (value: number) => void
  } | null
  onSearchSubmit: null | ((value: string) => void)
}) {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const telemetry = useBETelemetry()

  const [searchBtn, setSearchBtn] = useState<Boolean>(false)
  const [searchText, setSearchText] = useState<string>("")

  const clickedPostHandler = (label: string, slug: string) => {
    telemetry.track({
      event: "click_post",
      properties: {
        label,
        target_blog: slug,
      },
    })
    if (slug === "" || slug === undefined) return

    const url = getLinkUrl(t("blog.slugs.articlePage", { articleSlug: slug }))
    navigate(url)
  }

  const getHighlightedText = (text: string, highlight: string) => {
    if (!highlight.trim()) return text
    const regex = new RegExp(`(${highlight})`, "gi")
    const contents = text.split(regex)

    return contents.map((content, index) =>
      content.toLocaleLowerCase() === highlight.toLocaleLowerCase() ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {content}
        </span>
      ) : (
        content
      )
    )
  }

  useEffect(() => {
    if (searchVal) setSearchText(searchVal)
  }, [searchVal])

  return (
    <>
      <Section>
        <Box className={classes.categorBody}>
          <Grid spacing={{ lg: 7, md: 4, sm: 4, xs: 2 }} container>
            {articlesData.length !== 0 ? (
              articlesData.map((article) => (
                <Grid xs={12} md={4} sm={6} item key={article.id}>
                  <Stack className={classes.categoryImgContainer}>
                    {article?.heroImage?.file?.url ? (
                      <Box
                        component="img"
                        src={article?.heroImage?.file?.url || ""}
                        alt={article?.heroImage?.title || ""}
                        className={classes.categoryImg}
                        onClick={() => article.title && article.slug ? clickedPostHandler(article.title, article.slug) : null}
                        loading="lazy"
                      />
                    ) : null}
                    <Typography
                      onClick={() => article.title && article.slug ? clickedPostHandler(article.title, article.slug) : null}
                      className={classes.categoryDescription}
                    >
                      {article.title ? getHighlightedText(article.title, searchVal || ""): ""}
                    </Typography>
                  </Stack>
                </Grid>
              ))
            ) : (
              <Grid item xs={12} sm={12}>
                <Typography className={classes.searchResultText}>{noResultText}</Typography>
              </Grid>
            )}
            {pagination && pagination.numPages > 1 && (
              <Grid xs={12} sm={12} item>
                <Box className={classes.paginationContainer}>
                  <Pagination
                    className={classes.bodyPagination}
                    count={pagination.numPages}
                    page={pagination.currentPage}
                    onChange={(_, page) => pagination.onChange(page)}
                  />
                </Box>
                <Box className={classes.paginationMobileContainer}>
                  {pagination.currentPage > 1 && (
                    <Button
                      className={classes.mobiePaginationContainer}
                      onClick={() => pagination.onChange(pagination.currentPage - 1)}
                    >
                      <KeyboardArrowLeftIcon />
                    </Button>
                  )}
                  {pagination.currentPage < pagination.numPages && (
                    <Button
                      className={classes.mobiePaginationContainer}
                      onClick={() => pagination.onChange(pagination.currentPage + 1)}
                    >
                      <KeyboardArrowRightIcon />
                    </Button>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Section>
      {onSearchSubmit && (
        <Box className={classes.bottomSearchContainer}>
          <Section>
            <Stack spacing={1} className={classes.noResultContainer} alignItems={"center"} position="relative">
              <Typography className={classes.searchText}>{t("blog.category.body.bodySearchTitle")}</Typography>
              <form
                className={classes.searchInputBtnContainer}
                onSubmit={(e) => {
                  e.preventDefault()
                  onSearchSubmit(searchText)
                }}
              >
                <Box className={classes.inputSearchResultContainer}>
                  <SearchIcon />

                  <InputBase
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder={t("blog.category.body.searchPlaceholder")}
                    onFocus={() => setSearchBtn(true)}
                    onBlur={() => setSearchBtn(false)}
                  />
                  <Box
                    onMouseDown={(e) => {
                      e.stopPropagation()
                      setSearchText("")
                    }}
                  >
                    <ClearIcon className={classes.clearIcon} sx={{ display: `${searchText ? "block" : "none"}` }} />
                  </Box>
                </Box>

                <Button type="submit" className={searchBtn ? classes.searchBtnShow : classes.searchButton}>
                  {t("blog.category.body.searchButtonText")}
                </Button>
              </form>
            </Stack>
          </Section>
        </Box>
      )}
    </>
  )
}
